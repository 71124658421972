@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700;800&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: "Open Sans" !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
li,
a,
button {
    font-family: "Open Sans" !important;
}

img {
    max-width: 100%;
}

.text-decoration {
    text-decoration: none !important;
}

.MuiContainer-root.main_container {
    width: 100%;
    max-width: 430px;
    padding: 0;
    margin: 0 auto;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    position: relative;
    overflow: hidden;
}

.page_back_btn_ltr img {
    width: 18px;
    height: 18px;
}

.page_back_btn_rtl img {
    width: 18px;
    height: 18px;
    transform: rotate(180deg);
}

img.ltrimg {
    transform: rotate(180deg);
}

.header_main {
    position: relative;
    z-index: 2000;
}

header.header_appbar {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: fixed;
    max-width: 430px;
}

.header_appbar .MuiToolbar-root {
    min-height: 65px;
    max-height: 65px;
    padding: 0 35px;
    justify-content: space-between;
}

.header_appbar .MuiToolbar-root button {
    color: #1E1E1E;
    padding: 0;
    margin: 0;
    min-width: 30px;
}

.header_appbar .main_logo {
    line-height: 10px;
    align-self: end !important;
}

.header_appbar .main_logo img.logo {
    /* -webkit-transform: translateY(50%); */
    /* transform: translateY(50%); */
    margin-bottom: -25px !important;
    width: 50px;
    height: 50px;
}



.header_appbar button.nav_icon svg {
    width: 30px;
    height: 30px;
}

.header_appbar .MuiToolbar-root button:hover {
    background: transparent;
}

.page_default {
    height: calc(100vh - 70px);
    overflow: auto;
    margin-top: 66px;
}

.page_default::-webkit-scrollbar {
    width: 0;
}

.page_default::-webkit-scrollbar-thumb {
    background: #afafaf;
}

.about_page_main {
    padding: 55px 34px;
}

.about_page_main .content h1 {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    margin-bottom: 40px;
}

.about_page_main .content h2 {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    margin-bottom: 40px;
}

.about_page_main .content p {
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    color: #000000;
    margin-bottom: 30px;
}

.menu_drawer .menu_list {
    background: #fff;
    padding-top: 33px;
}

.menu_scrollbar {
    overflow: auto;
    height: calc(100vh - 90px);
}

.menu_scrollbar::-webkit-scrollbar {
    width: 4px;
}

.menu_scrollbar::-webkit-scrollbar-thumb {
    background: #afafaf;
}

.menu_drawer .menu_drawer_header {
    min-height: auto;
    display: block;
    padding: 40px 35px 0 39px;
    position: relative;
}

/* .menu_drawer .menu_drawer_header button.MuiButtonBase-root { */
/* position: absolute;
  padding: 0;
  top: 5px;
  left: 5px; */
/* } */

.menu_drawer .menu_drawer_header button {
    background-color: black !important;
    color: #FFFFFF !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

}

.menu_drawer .menu_drawer_header .menu_logo_lan {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.menu_drawer .menu_drawer_header a.menu_main_logo {
    line-height: 10px;
}

.menu_drawer .menu_list .MuiListSubheader-root {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    font-family: 'Open Sans';
    color: #A2A2A2;
    padding: 0 35px 5px 39px;
    position: static;
}

.menu_drawer .menu_list .MuiListItem-root .MuiButtonBase-root {
    padding: 0 35px 30px 39px;
}

.menu_drawer .menu_list .MuiListItem-root .MuiListItemText-root {
    margin: 0;
}

.menu_drawer .menu_list .MuiListItem-root .MuiListItemText-root span {
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #1E1E1E;
}

.menu_drawer .MuiPaper-root {
    background: #FFFFFF;
    box-shadow: 0px 4px 8px 10px rgba(0, 0, 0, 0.25);
    display: table;
}

.lang_selector .menu_lng .MuiInputBase-root {
    line-height: normal;
}

.lang_selector .menu_lng .MuiInputBase-root .MuiSelect-select {
    padding: 0;
    min-height: auto;
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #1E1E1E;
}

.select_lang_item {
    min-height: auto !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    line-height: 16px !important;
    color: #1E1E1E !important;
    background: none !important;
    padding: 4px 12px !important;
    text-transform: capitalize !important;
}

.lang_selector .menu_lng .MuiInputBase-root svg {
    display: none;
}

.lang_selector .menu_lng .MuiInputBase-root fieldset {
    display: none;
}

.menu_drawer .menu_order_confirm {
    padding: 2px 35px 35px;
}

.menu_drawer .menu_order_confirm .MuiBox-root {
    background: #46CC94;
    border-radius: 15px;
    padding: 14px 15px;
}

.menu_drawer .menu_order_confirm p {
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
    color: #FFFFFF;
}

.menu_drawer .menu_order_confirm h5 {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
}

.menu_drawer .menu_order_confirm .inner_h5 {
    background: none;
    border-radius: 0;
    padding: 16px 0;
}

.menu_drawer .menu_footer .policy_pages {
    padding: 10px 0 35px;
}

.menu_drawer .menu_footer .policy_pages .footer_list_nav {
    padding: 0 35px 0 39px;
    display: flex;
    justify-content: space-between;
}

.menu_drawer .menu_footer .policy_pages li.MuiListItem-root {
    width: auto;
}

.menu_drawer .menu_footer .policy_pages .MuiButtonBase-root {
    padding: 0 0 22px 0;
}

.menu_drawer .menu_footer {
    background: #fff;
}

.menu_drawer .menu_footer .policy_pages .MuiButtonBase-root .MuiListItemText-root {
    margin: 0;
}

.menu_drawer .menu_footer .policy_pages .MuiButtonBase-root .MuiListItemText-root span {
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
    color: #949494;
}

.menu_drawer .menu_footer .copywrite_text {
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
    color: #949494;
    padding: 0 35px 35px 39px;
}

.menu_drawer .menu_list .MuiListItem-root .MuiButtonBase-root:hover {
    background: none;
}

.menu_drawer .menu_footer .policy_pages .MuiButtonBase-root:hover {
    background: none;
}

.location_boxes {
    padding: 20px 28px;
    padding-bottom: 40px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 15px;
    justify-content: space-between;
    align-items: center;
    column-gap: 20px;
}

.location_boxes .boxes {
    width: 100%;
    flex-basis: calc(50% - 10px);
    max-width: calc(50% - 10px);
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    padding: 16px 12px;
}

.location_boxes .boxes h2 {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    margin-bottom: 4px;
    word-break: break-word;
    /* text-overflow: ellipsis;
    overflow: hidden; */
    /* white-space: nowrap; */
}

.location_boxes .boxes .text_with_icon {
    display: flex;
    align-items: center;
    gap: 8px;
}

.location_boxes .boxes .text_with_icon h5 {
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.location_boxes .boxes .text_with_icon p {
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
    color: #C8C8C8;
    margin-left: 4px;
}

.profile_card .profile_img img {
    max-width: 68px;
    border-radius: 50%;
}

.profile_card .profile_img {
    margin-bottom: 22px;
}

.profile_card .profile_name h1 {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #1E1E1E;
    margin-bottom: 8px;
}

.profile_card .profile_name p.tag_line {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #EFBE7D;
    margin-bottom: 8px;
}

.profile_card .customer_level {
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile_card .customer_level span.level_line {
    display: inline-block;
    width: 162px;
    height: 1px;
    background: #DEDEDE;
    position: relative;
}

.profile_card .customer_level span.level_line::before {
    content: '';
    height: 3px;
    display: inline-block;
    background: #EFBE7D;
    width: 80%;
    position: absolute;
    left: 0;
    transform: translateY(-50%);
    top: 50%;
}

.profile_card .customer_level span.level_num {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #EFBE7D;
    padding-left: 7px;
}

.profile_page_main .profile_card {
    padding: 60px 15px 30px 15px;
    text-align: center;
    border-radius: 0;
    box-shadow: none;
}

.profile_page_main .edit_options {
    padding: 42px;
}

.edit_options .fields {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
}

.edit_options .fields h2 {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #1E1E1E;
    display: inline-block;
}

.ltr .fields button.MuiButtonBase-root {
    padding: 0;
    min-width: auto;
    transform: rotate(180deg);
}

.ltr .fields button.MuiButtonBase-root {
    padding: 0;
    min-width: auto;
    /* transform: rotate(180deg); */
}

.edit_options .fields button.MuiButtonBase-root img {
    height: 15px;
    width: auto;
}

.my_orders_page .my_orders {
    padding: 0 8px 20px;
}

.my_orders_page .my_orders>h2.MuiTypography-root {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #1E1E1E;
    margin-bottom: 16px;
    padding: 0 18px;
}

.my_orders .orders {
    background: transparent;
    border-radius: 15px;
    padding: 22px 20px;
    margin-bottom: 8px;
    padding-right: 8px;
}

.arabic .my_orders .orders {
    padding-right: 20px;
    padding-left: 8px;
}

.my_orders .orders .headings .sar_total {
    padding: 0 10px;
}

.my_orders .orders .headings {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
}

.my_orders .orders .no_order {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.my_orders .orders .no_order h3 {
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    display: inline-block;
}

.my_orders .orders .headings h3 {
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    display: inline-block;
}

.my_orders .orders .order_details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
}

.my_orders .orders .order_details .cake_detail p {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
}

.my_orders .orders .order_details .progress_report button {
    border: 1px solid;
    border-radius: 15px;
    height: 30px;
    width: auto;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-align: right;
    padding: 6px 12px;
    text-transform: capitalize;
    color: #000;
    cursor: text;
}

.my_orders.MuiBox-root.css-0 {
    padding: 0px 7px;
}



.my_orders .orders .date_details .pickup_details {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
}

.my_orders .fields_order_value {
    padding-left: 2px;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 9px;
}

.my_orders .orders .date_details p.date {
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
}

.my_orders .orders.in_process {
    background: #46CC94;
    color: #fff;
}

.my_orders .orders.in_process .progress_report button {
    color: #fff;
}

.my_orders .orders.completed {
    background: #DCDCDC;
}

.my_orders .orders.refunded {
    background: #EFBE7D;
}

.my_order_details .content {
    padding: 55px 45px;
}




.my_orders_page .my_orders_one {
    padding: 0 8px 20px;
}

.my_orders_page .my_orders_one>h2.MuiTypography-root {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #1E1E1E;
    margin-bottom: 16px;
    padding: 0 18px;
}

.my_orders_one .orders {
    background: transparent;
    border-radius: 15px;
    padding: 22px 20px;
    margin-bottom: 8px;
    padding-right: 8px;
}

.arabic .my_orders_one .orders {
    padding-right: 20px;
    padding-left: 8px;
}

.my_orders_one .orders .headings .sar_total {
    padding: 0 10px;
}

.my_orders_one .orders .headings {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
}

.my_orders_one .orders .no_order {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.my_orders_one .orders .no_order h3 {
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    display: inline-block;
}

.my_orders_one .orders .headings h3 {
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    display: inline-block;
}

.my_orders_one .orders .order_details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
}

.my_orders_one .orders .order_details .cake_detail p {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
}

.my_orders_one .orders .order_details .progress_report button {
    border: 1px solid;
    border-radius: 15px;
    height: 30px;
    width: auto;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-align: right;
    padding: 6px 12px;
    text-transform: capitalize;
    color: #000;
}

.my_orders_one.MuiBox-root.css-0 {
    padding: 0px 37px;
}



.my_orders_one .orders .date_details .pickup_details {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
}

.my_orders_one .fields_order_value {
    padding-left: 2px;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 9px;
}

.my_orders_one .orders .date_details p.date {
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
}

.my_orders_one .orders.in_process {
    background: #46CC94;
    color: #fff;
}

.my_orders_one .orders.in_process .progress_report button {
    color: #fff;
}

.my_orders_one .orders.completed {
    background: #DCDCDC;
}

.my_orders_one .orders.refunded {
    background: #EFBE7D;
}



.ocassion_detail .text_btn h1 {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #1E1E1E;
    margin: 8px auto;
}

.ocassion_detail .text_btn button {
    background: #46CC94;
    border: 1px solid #FFFFFF;
    border-radius: 15px;
    box-shadow: none;
    padding: 5px 9px;
    font-weight: 600;
    font-size: 14px;
    /* line-height: 19px; */
    color: #FFFFFF;
    font-family: 'Open Sans';
    text-transform: none;
    height: 30px;
    cursor: text;
}

.ocassion_detail .text_btn {
    display: flex;
    justify-content: space-between;
}

.ocassion_detail .text_btn h2 {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #1E1E1E;
    margin-top: 0px;
}

.images_attached {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 22px;
    gap: 4px;
}

.images_attached .img_box {
    width: 82px;
    height: 82px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.images_attached .img_box img {
    object-fit: cover;
}

.other_details .box h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #1E1E1E;
    margin-bottom: 12px;
}

.other_details {
    margin-top: 22px;
}

.other_details .box p {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #7C7C7C;
}

.other_details .box ul {
    padding: 0;
    margin-bottom: 22px;
}

.other_details .box ul li {
    padding: 0;
    display: list-item;
    list-style-type: disc;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #7C7C7C;
    list-style-position: inside;
}

.other_details .box ul li .MuiListItemText-root {
    margin: 0;
    display: inline-block;
}

.other_details .box ul li span {
    font-size: 12px;
    line-height: 16px;
}

.other_details .box p:last-child {
    margin-bottom: 22px;
}

.start_page {
    padding: 35px 35px;
}

.start_category {
    margin-bottom: 16px;
    background-color: #F8B5C4;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
}

.start_category .category_image {
    line-height: 10px;
}

.start_category .category_image img {
    object-fit: cover;
    border-radius: 10px;
    width: 100%;
    height: auto;
    max-height: 180px;
}

.category_title {
    position: absolute;
    background: rgba(255, 255, 255, 0.53);
    bottom: 0;
    left: 0;
    right: 0;
    padding: 4px 10px;
}

.category_title h1 {
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #1E1E1E;
}

.category_box {
    margin-bottom: 19px;
    background-color: #F8B5C4;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
}

.category_box .cat_image {
    line-height: 10px;
}

.category_box .cat_image img {
    object-fit: cover;
    border-radius: 10px;
    width: 100%;
    height: auto;
}

.cat_title {
    position: absolute;
    background: rgba(255, 255, 255, 0.53);
    bottom: 0;
    left: 0;
    right: 0;
    padding: 4px 12px;
}

.cat_title h1 {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #1E1E1E;
}

.start_next_page {
    padding: 30px;
}

.start_next_page .heading {
    padding-top: 25px;
    padding-bottom: 40px;
}

.start_next_page .heading h1 {
    font-weight: 800;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #000000;
    max-width: 200px;
    margin: 0 auto;
}

.start_next_page .category_options {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 25px;
}

.start_next_page .category_options .category_box {
    flex-basis: calc(50% - 12.5px);
    max-width: calc(50% - 12.5px);
    margin-bottom: 0;
    /* height: 171px; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.category_box.box_1 {
    background-color: #ffffff;
}

.category_box.box_2 {
    background-color: #EFBE7D;
}

.category_box.box_3 {
    background-color: #8DC8E8;
}

.category_box.box_4 {
    background-color: #B5E3D8;
}

.drawer_main_div {
    z-index: 2500 !important;
}

button.drawer_btn_verif {
    /* min-width: auto; */
    min-width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
}

.drawer_main_div .MuiBackdrop-root.MuiModal-backdrop {
    background-color: rgb(60 60 60 / 30%);
    max-width: 430px;
    margin: 0 auto;
}

.drawer_main_div>.MuiPaper-root.MuiPaper-elevation {
    max-width: 430px;
    margin: 0 auto;
    border-radius: 28px 28px 0 0;
    box-shadow: none;
}

.drawer_main_div .verification_popup .verif_content {
    padding: 23px 27px;
    background: #fff;
}

.verification_popup .verif_content h4 {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    margin-bottom: 23px;
}

.verif_content .num_auth_code .MuiInputBase-root {
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    margin-bottom: 23px;
}

.verif_content .num_auth_code .MuiInputBase-root::before {
    border-bottom: 2px solid #CFCFCF;
}

.verif_content .num_auth_code .MuiInputBase-root input {
    padding-bottom: 4px;
}

.verif_content .num_auth_code .MuiInputBase-root::after {
    border-color: #e293a7;
}

.verif_content .num_auth_code p.MuiTypography-root {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #B7B7B7;
    margin-bottom: 30px;
}

.verif_content .num_auth_code .verify_btn,
.verif_content .num_auth_code .submit_btn {
    background: #D9D9D9;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: 100%;
    height: 70px;
    font-weight: 800;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #000000;
    font-family: 'Open Sans';
    margin-bottom: 10px;
}

.active_btn {
    background: #F8B5C4 !important;
}

.verif_content .num_auth_code .resend_otp {
    min-width: 100px;
    font-size: 11px;
    height: 26px;
    background: #F8B5C4;
    font-weight: 700;
    color: black;
    border-radius: 10px;
    /* line-height: 18px; */
}

/* .uploaded-image {
  width: 25%;
  position: relative;
  top: 1px;
  left: -7px;
  bottom: 37px;
} */

.uploaded-image {
    min-width: 25%;
    height: 100%;
    position: relative;
    top: 2px;
    /* left: 1px; */
    bottom: 37px;
    margin-right: 3px;
}

.uploaded-images-container {
    display: flex;
    justify-content: center;
    padding: 8px 5px;
    column-gap: 2px;
}


img.small-image {
    border: 1px solid white;
    border-radius: 10px;
    background: white;
    min-width: 77px;
    max-width: 75px;
    min-height: 75px;
    max-height: 75px;
    object-fit: contain;
}

.small-description {
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    line-height: 16px;
    font-size: 12px;
    font-weight: 400;
    /* background: #fafafa; */
    min-height: 70px !important;
    max-height: 70px !important;
    min-width: 100%;
    max-width: 100%;
    padding: 8px;
    /* height: 70px !important; */
    overflow: auto !important;
    font-family: "Open Sans" !important;
}

.verif_content.code_verify h4 {
    margin-bottom: 12px;
}

.verif_content.code_verify .num_auth_code .ReactInputVerificationCode__container {
    column-gap: 25px;
    margin-bottom: 22px;
    width: 70%;
    direction: ltr;
}

.verif_content.code_verify .num_auth_code div[role="button"] {
    box-shadow: none;
    border-bottom: 2px solid #CFCFCF;
    border-radius: 0;
    height: 64px;
    font-weight: 700;
    font-size: 48px;
    line-height: 65px;
    color: #000000;
    font-family: 'Open Sans';
    flex: 1;
}

.delivery_pickup_tabs>.MuiBox-root {
    border: none;
}

.delivery_pickup_tabs>.MuiBox-root .tabs_button .MuiTabs-flexContainer {
    justify-content: center;
    align-items: center;
    width: 100%;
}

.delivery_pickup_tabs>.MuiBox-root .tabs_button .MuiTabs-flexContainer button {
    width: 50%;
    padding: 24px;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #C8C8C8;
    font-family: 'Open Sans' !important;
    text-transform: capitalize;
    flex-direction: row-reverse;
}

.delivery_pickup_tabs>.MuiBox-root .tabs_button .MuiTabs-flexContainer button img {
    position: absolute;
    right: 36px;
    display: none;
}

.delivery_pickup_tabs>.MuiBox-root .tabs_button .MuiTabs-flexContainer button.Mui-selected img {
    display: block;
}

.delivery_pickup_tabs>.MuiBox-root .tabs_button .MuiTabs-flexContainer button.Mui-selected {
    color: #000000;
}

.delivery_pickup_tabs>.MuiBox-root .tabs_button .MuiTabs-indicator {
    height: 5px;
    background: #F8B5C4;
    border-radius: 15px;
}

.delivery_pickup_tabs div[role="tabpanel"]>.MuiBox-root {
    padding: 0;
}

.delivery_pickup_tabs .location_boxes.pickup_tab {
    padding: 24px 28px;
    column-gap: 20px;
    row-gap: 18px;
}

.delivery_pickup_tabs .location_boxes.pickup_tab_tab {
    padding: 306px 28px;
}

.location_boxes.pickup_tab .boxes button {
    background: #46CC94;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    margin-top: 40px;
    width: 100%;
    padding: 7px 15px;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #FFFFFF;
    font-family: 'Open Sans';
}

.date_picker_main_box.MuiBox-root.css-0 {
    align-content: center;
    margin: 0 auto;
    padding: 24px 28px 10px 28px;
    column-gap: 20px;
    row-gap: 18px;
}

.drawer_main_div_pickup .MuiBackdrop-root.MuiModal-backdrop {
    background-color: rgb(60 60 60 / 30%);
    max-width: 430px;
    margin: 0 auto;
}

.drawer_main_div_pickup .MuiPaper-root {
    max-width: 430px;
    margin: 0 auto;
    border-radius: 28px 28px 0 0;
    box-shadow: none;
}

.search_close_none {
    display: none !important;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.active_category {
    background: #625b5b;
    color: white;
}

.location_modal_content .modal_start_category .start_category {
    max-width: 115px;
    display: inline-block;
    margin: 0;
}

.location_modal_content .modal_start_category {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 16px;
    margin-top: 35px;
}

.chooselocation_modal .location_modal_content {
    padding: 25px 30px 44px;
}

.location_modal_content h4.MuiTypography-root {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    margin-bottom: 16px;
}

.location_modal_content h6.MuiTypography-root {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
}

.location_modal_content .modal_start_category .start_category {
    max-width: 115px;
    display: inline-block;
    margin: 0;
}

.location_modal_content .modal_start_category .start_category .category_title {
    background: rgba(255, 255, 255, 0.53);
    padding: 4px 10px;
}

.location_modal_content .modal_start_category .start_category .category_title h1 {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #1E1E1E;
    padding: 0;
}

.location_boxes.pickup_tab .boxes button.fully_booked_btn {
    background: #EFBE7D;
    padding: 7px;
}

.gallery_page_main {
    padding: 40px 36px 40px;
}

.card_with_bg .card_with_bg_inner h1 {
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    max-width: 65%;
    margin: 0 auto;
}

.gallery_page_main .card_with_bg {
    background-image: url('/public/images/gallery_sec_bg.jpg');
    border-radius: 12px;
    background-position: center center;
}

.card_with_bg .card_with_bg_inner {
    padding: 16px;
    text-align: center;
}

.card_with_bg .card_with_bg_inner button {
    margin-top: 12px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    padding: 13px 36px;
    color: #000000;
}

.gallery_main {
    margin-top: 20px;
}

.gallery_main h2 {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #000000;
    margin-bottom: 24px;
}

.gallery_main .slider_btns button {
    min-width: auto;
    padding: 0;
    margin-right: 18px;
    color: rgba(0, 0, 0, 1);
}

.gallery_main .slider_btns button.with_text {
    background: #D9D9D9;
    border-radius: 10px;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #000000;
    margin-right: 14px;
    padding: 7px 15px;
    white-space: nowrap;
}

.gallery_main .slider_btns {
    width: 100%;
    overflow-x: auto;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    padding-bottom: 12px;
}

.gallery_main .slider_btns::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.gallery_main .slider_btns::-webkit-scrollbar-thumb {
    background: #acacac;
}

.gallery_main .slider_btns::-webkit-scrollbar {
    height: 0;
}

.gallery_boxes {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 18px;
}

.gallery_boxes .box_g {
    flex-basis: calc(50% - 10px);
    max-width: calc(50% - 10px);
    margin-bottom: 0;
    /* height: 150px; */
    height: 175px;

    background: #FFFFFF;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.custom_cake {
    margin-top: 68px;
    text-align: center;
    margin-bottom: 10px;
    padding: 0 50px;
}

.custom_cake img {
    max-width: 65%;
}

.customize_page h1 {
    font-weight: 800;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #000000;
    max-width: 40%;
    margin: 0 auto;
    margin-bottom: 40px;
}

.upload_img_opt {
    padding: 0 35px 26px;
}

.upload_img_opt label.MuiButtonBase-root {
    background: #D9D9D9;
    border: 2px dashed #7C7C7C;
    border-radius: 10px;
    width: 100%;
    /* padding: 30px !important; */
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #000000;
    font-family: 'Open Sans';
    min-height: 80px;
}

.upload_inst {
    padding: 0 35px 0;
    margin-bottom: 115px;
}

.upload_inst label {
    background: #fafafa;
    border: 1px solid #D9D9D9;
    display: block;
    padding: 12px 19px 19px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #7C7C7C;
    border-radius: 10px;
}

.step_button_box {
    /* margin-top: 50px; */
    padding: 0 28px;
    padding-bottom: 12px;
    padding-top: 12px;
    display: flex;
    align-items: center;
    column-gap: 30px;
    justify-content: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: 430px;
    background-color: #fff;
}

.step_button_box .text {
    width: 24%;
}

.step_button_box .btn {
    width: 76%;
    max-width: 260px;
}

.step_button_box .text h4 {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #000000;
    margin-bottom: 4px;
}

.step_button_box .text p {
    font-weight: 700;
    font-size: 8px;
    line-height: 11px;
    text-align: center;
    color: #000000;
}

.step_button_box .btn .upload_btn {
    background: #F8B5C4;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    font-weight: 800;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #000000;
    font-family: 'Open Sans';
    text-transform: capitalize;
    width: 100%;
    display: block;
    padding: 21px;
}

.googlemap iframe {
    width: 100%;
}

.cust_second_step .custom_cake {
    margin-top: 35px;
}

.page_default.cust_second_step h1 {
    margin-bottom: 20px;
}

.page_default.cust_second_step .step_button_box {
    margin-top: 48px;
}

.range_bars_custom {
    padding: 0 48px;
    margin-bottom: 120px;
}

.range_bars_custom .individual_bars {
    margin-bottom: 5px;
}

.cust_third_step h1 {
    margin-bottom: 22px;
}

.flavor_main {
    padding: 0 36px;
}

.flavor_main {
    padding: 0 36px;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    flex-wrap: wrap;
    column-gap: 24px;
    /* row-gap: 6px; */
    row-gap: 0px;
    margin-bottom: 115px;
}

.flavor_main .flavor_box {
    width: 100%;
    flex-basis: calc(33.33% - 16px);
    max-width: calc(33.33% - 16px);
    /* margin-left: 5px; */
}

.flavor_main .flavor_box label {
    font-weight: 800;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #000000;
    display: flex;
    justify-content: center;
    /* display: block; */
    margin-bottom: 3px;
    font-family: 'Open Sans';
    /* text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; */
}

.flavor_box .flavor_img img {
    width: 100%;
    object-fit: cover;
    cursor: pointer;
    border-radius: 20px;
}

.flavored_text {
    padding: 8px !important;
}

/* .flavoured_box {
  margin-bottom: 12px;
  margin-top: 10px;
  display: flex;
  height: 87px;
} */

.drawer_main_checkout .MuiBackdrop-root.MuiModal-backdrop {
    background-color: transparent;
    max-width: 430px;
    margin: 0 auto;
}

.drawer_main_checkout>.MuiPaper-root.MuiPaper-elevation {
    max-width: 430px;
    margin: 0 auto;
    border-radius: 12px 12px 0 0;
    box-shadow: none;
}

.checkout_drawer {
    background: #F8B5C4;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
    border-radius: 12px 12px 0 0;
}

.checkout_drawer .checkout_drawer_content h1.head_title {
    color: #000;
    font-size: 20px;
    font-weight: 700;
    line-height: 27px;
    margin-bottom: 20px;
    padding: 28px 27px 0px 27px;
}

button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.page_back_btn_rtl {
    padding: 15px 10px 14px 2px;
}

button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.page_back_btn_ltr {
    padding: 15px 10px 14px 2px;
}

.checkout_drawer_content h2 {
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
}

.checkout_drawer .checkout_drawer_content {

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* min-height: 500px; */
    width: 100%;
}

.step_button_box.Confirm_pay {
    border-top: 1px solid rgb(0 0 0 / 25%);
    padding-top: 18px;
    background-color: #f8b5c4;
    position: static;
}

.step_button_box.Confirm_pay .btn button {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.checkout_drawer_content .inner_box {
    padding: 0 27px 20px;
}

.checkout_drawer_content .inner_box_contain {
    padding: 0 27px 7px;
    font-weight: 600;
    font-size: smaller;

}


.checkout_drawer_content .inner_box .headers {
    border-top: 1px solid rgb(0 0 0 / 25%);
    padding-top: 20px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.checkout_drawer_content .inner_box .details p {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
    margin-bottom: 6px;
}

.checkout_drawer_content .inner_box .totals {
    margin-top: 9px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.checkout_drawer_content .inner_box .totals_main {
    border-top: 1px solid rgb(0 0 0 / 25%);
    padding-top: 15px;
    margin-bottom: 7px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.checkout_drawer_content .inner_box .vat_details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 9px;
}

.checkout_drawer_content .inner_box .vat_details span {
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.46);
}

.checkout_drawer_content .inner_box .totals_vat {
    margin-bottom: 7px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

a.text-decoration {
    text-decoration: none;
}

#menu- {
    z-index: 2000;
}

.gallery_boxes .box_g .img {
    /* padding: 15px; */
    padding: 30px;
    font-size: 11px;
    text-align: center;
}


.text_state {
    margin-bottom: 18px;
    font-weight: 800;
    text-transform: capitalize;
}

.name_text {
    font-weight: 800;
}

.price_text {
    font-weight: 700;
    color: var(--toastify-text-color-light);
}

.gallery_boxes .box_g .img img {
    object-fit: contain;
}

.menu_drawer .menu_drawer_header .menu_main_logo img {
    width: 50px;
    height: 50px;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 250ms ease-in;
}

button.cake_modal_btn {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: none;
    min-width: 100%;
}

button.cake_modal_btn:hover {
    background: transparent;
}

.drawer_main_div .MuiBackdrop-root.MuiModal-backdrop {
    background-color: rgb(60 60 60 / 30%);
    max-width: 430px;
    margin: 0 auto;
}

.cake_modal_main .cake_modal_box {
    max-width: 357px;
    margin: 0 auto;
    text-align: center;
    /* padding: 75px 18px 20px; */
    top: 146px;
    transform: translate(-50%, 0);
    border-radius: 10px;
}

.cake_modal_main .cake_modal_box button.cross_btn {
    position: absolute;
    top: 8px;
    right: 10px;
    padding: 0;
    min-width: auto;
    color: #000;
}

.cake_modal_box button.submit_btn {
    background: #F8B5C4;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
    border-radius: 12px;
    min-width: auto;
    width: 100%;
    padding: 15px;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #000000;
}

.cake_modal_main .cake_modal_box img {
    margin-bottom: 0px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 209px;
    height: 209px;
}

.cake_modal_box.MuiBox-root {
    height: 283px;
    padding: 0px 21px;
}

.cake_modal_main .MuiBackdrop-root.MuiModal-backdrop {
    background: rgb(0 0 0 / 30%);
    mix-blend-mode: multiply;
    filter: blur(2px);
    max-width: 430px;
    margin: 0 auto;
}

.checkout_drawer .checkout_drawer_content .content_main {
    height: 100%;
    margin-bottom: 0;
}

.range_bars_custom .individual_bars span.MuiSlider-root.MuiSlider-colorPrimary {
    padding: 15px 0;
}

.cust_second_step .custom_cake img {
    max-width: 72%;
}

.drawer_dropshadow {
    position: fixed;
    align-items: center;
    justify-content: center;
    inset: 0px;
    background-color: rgb(0 0 0 / 30%);
    -webkit-tap-highlight-color: transparent;
    z-index: 1100;
    width: 100%;
    max-width: 430px;
    margin: 0 auto;
}

.drawer_dropshadow {
    display: none;
}

.drawer_dropshadow.sidebar_active {
    display: block;
    position: fixed;
    z-index: 1199;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.drawer_dropshadow button {
    width: 100%;
    height: 100%;
    padding: 0;
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
}

.pay .pickup_details span,
.pay .date_time span {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
    opacity: 0.75;
    margin-bottom: 2px;
    display: block;
}

.pay .pickup_details,
.pay .date_time {
    padding-bottom: 14px;
    margin-bottom: 9px;
    border-bottom: 1px solid rgb(0 0 0 / 25%);
}

.pay .date_time {
    margin-bottom: 2px;
}

.pay .order_for {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.pay .order_for button {
    padding: 0;
    min-width: auto;
}

.pay .order_for button svg {
    font-size: 20px;
    color: #000;
}

.pay .inner_box .promocode fieldset {
    display: none;
}

.pay .inner_box .promocode {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* border-top: 1px solid rgb(0 0 0 / 25%); */
    margin-top: 2px;
    padding-top: 10px;
    column-gap: 30px;
}

.pay .inner_box .promocode input {
    background: #FFFFFF;
    border-radius: 10px;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    font-family: 'Open Sans';
    width: 100%;
    height: auto;
    padding: 9px 16px;
}

.pay .inner_box .promocode input::placeholder {
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #B7B7B7;
    opacity: 1;
}

.pay .inner_box .promocode button {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
    padding: 9px 25px;
}

.pay .step_button_box.Confirm_pay {
    margin-top: 0;
}

.pay .card_options {
    /* padding: 20px 27px 22px; */
    padding: 2px 27px 2px;
    border-top: 1px solid rgb(0 0 0 / 25%);
}

.pay .card_options .cards button {
    padding: 0;
    min-width: auto;
    color: #000;
}

.pay .card_options .cards button svg {
    font-size: 20px;
    color: #000;
}

.pay .card_options .cards {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.inner_box.promocode_box {
    padding-bottom: 10px;
}

.arabic .menu_drawer .menu_list .MuiListItem-root .MuiListItemText-root {
    text-align: right;
}

.arabic .profile_card .customer_level span.level_line::before {
    right: 0;
}

.arabic .profile_card .customer_level span.level_num {
    padding-left: 0;
    padding-right: 8px;
}

.arabic .other_details .box ul li {
    text-align: right;
}

.arabic .verif_content .num_auth_code .MuiInputBase-root input {
    direction: ltr;
}

.arabic .delivery_pickup_tabs>.MuiBox-root .tabs_button .MuiTabs-flexContainer {
    direction: ltr;
}

.arabic .card_with_bg .card_with_bg_inner h1 {
    max-width: 35%;
}

.gallery_main .slider_btns_main {
    width: 100%;
    display: flex;
    align-items: flex-start;
}

.gallery_main .slider_btns_main button.search_btn {
    min-width: auto;
    margin-right: 18px;
    color: rgba(0, 0, 0, 1);
    padding: 5px 0;
}

.menu_drawer .menu_list .MuiListItem-root .order_menu {
    padding-bottom: 15px;
}

.thankyou_text {
    padding-top: 40px;
    padding-bottom: 48px;
}

.thankyou_text h1 {
    text-align: center;
    font-weight: 700;
    font-size: 40px;
    line-height: normal;
    text-align: center;
    color: #000000;
    font-family: Open Sans;

}

@media (max-width: 375px) {

    .ocassion_detail .text_btn button {
        background: #46CC94;
        border: 1px solid #FFFFFF;
        border-radius: 21px;
        box-shadow: none;
        padding: 20px 9px;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #FFFFFF;
        font-family: 'Open Sans';
        text-transform: none;
        height: 30px;
    }


}

@media (max-width: 375px) {
    .my_orders .orders .order_details .progress_report button {
        border: 1px solid;
        border-radius: 21px;
        height: 30px;
        width: auto;
        font-weight: 700;
        font-size: 14px;
        line-height: 15px;
        text-align: center;
        padding: 20px 18px;
        text-transform: capitalize;
    }
}

.order_number {
    text-align: center;
    margin-bottom: 34px;
}

.order_number p {
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: rgba(118, 118, 118, 1);
}

.order_number p strong {
    color: rgba(0, 0, 0, 1);
}

.sms-desc {
    text-align: center;
    margin-bottom: 34px;
}

.sms-desc p {
    font-weight: 400;
    font-size: 12px;
    font-style: normal;
    line-height: 19px;
    text-align: center;
    color: #7C7C7C;
    line-height: normal;
    max-width: 335px;
    margin: 0 auto;
    font-family: Open Sans;
}

.sms-desc p .dynamic_text {
    cursor: pointer;
}

.sms-desc p strong {
    /* color: rgba(0, 0, 0, 1); */
    font-weight: 800;
    color: #7c7c7c;
}

.thankyou_page .bottm_buttons {
    text-align: center;
    /* position: fixed;
    bottom: 10px; */
    width: 100%;
    max-width: 430px;
    margin-top: 70px;
}

.thankyou_page .bottm_buttons button {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    display: block;
    margin: 0 auto 27px;
    font-weight: 800;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #000000;
    padding: 21px 65px;
    text-transform: capitalize;
}

.search_bar_custom {
    width: auto;
    display: flex;
    align-items: center;
}

.search_bar_custom>.MuiFormControl-root {
    margin: 0;
    width: 100%;
    display: none;
}

.search_bar_custom input {
    line-height: 1.75;
    padding: 5px 10px;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.search_bar_custom fieldset {
    display: none;
}

.search_bar_custom input {
    line-height: 1.75;
    padding: 6px 10px;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-size: 14px;
    padding-right: 40px;
}

.search_bar_custom .search_close {
    position: absolute;
    right: 38px;
    top: 315px;
    min-width: auto;
    padding: 4px;
    color: rgba(0, 0, 0, 1);
    display: none;
}

.search_close.search_loader.MuiBox-root.css-hxrrb5 {
    justify-content: flex-end;
}

.slider_btns_main.search_active .slider_btns {
    display: none;
}

.slider_btns_main.search_active .MuiFormControl-root {
    display: block;
}

.slider_btns_main.search_active .search_close {
    display: block;
    position: absolute;
    top: 0px;
    left: 90%;
}

.slider_btns_main.search_active search.search_bar_custom {
    width: 100%;
    position: relative;
}

.delivery_pickup_tabs .tabs_button .delivery img {
    right: auto;
    left: 36px;
}

.delivery_pickup_tabs .delivery_tab_content .map_iframe {
    padding: 16px;
}

.delivery_pickup_tabs .delivery_tab_content iframe {
    border-radius: 12px;
}

.delivery_drag {
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: 430px;
    padding: 0 16px;
    padding-bottom: 25px;
    padding-top: 20px;
    display: block;
    background-color: #fff;
    text-align: center;
}

.delivery_drag>span.MuiTypography-root {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #000000;
    font-family: 'Open Sans';
    margin-bottom: 20px;
    display: block;
}

.delivery_drag .show_btn {
    background: #F8B5C4;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-weight: 800;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #000000;
    border-radius: 10px;
    padding: 25px 30px;
    width: 100%;
}

.drawer_main_delivery>.MuiBackdrop-root.MuiModal-backdrop {
    background-color: transparent;
    max-width: 430px;
    margin: 0 auto;
}

.drawer_main_delivery>.MuiPaper-root {
    max-width: 430px;
    margin: 0 auto;
    box-shadow: none;
}

.delivery_drawer .content_main {
    padding: 22px 26px 25px;
}

.delivery_drawer .content .timing {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 9px;
    font-family: 'Open Sans';
}

.delivery_drawer .content .timing span.time {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
    font-family: 'Open Sans';
}

.delivery_drawer .content .timing span.detail {
    font-weight: 700;
    font-size: 8px;
    line-height: 11px;
    text-align: right;
    font-family: 'Open Sans';
}

.delivery_drawer .content .current_status button {
    background: #FB4B4B;
    border-radius: 12px;
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
    color: #FFFFFF;
    padding: 2px 8px;
    margin-bottom: 11px;
    font-family: 'Open Sans';
}

.delivery_drawer .content .open_until>.time {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
    font-family: 'Open Sans';
    margin-bottom: 12px;
}

.delivery_drawer .content .open_until .grid {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 13px;
}

.delivery_drawer .content .open_until .grid .box .order {
    font-weight: 700;
    font-size: 8px;
    line-height: 11px;
    color: #000000;
    font-family: 'Open Sans';
    display: block;
}

.delivery_drawer .content .open_until .grid .box .sar {
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
}

.delivery_drawer .content_main .remark {
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
    text-align: center;
    color: #000000;
    display: block;
    padding-top: 85px;
    margin-bottom: 12px;
}

.delivery_drawer .content_main .submit_btn button {
    background: #F8B5C4;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-weight: 800;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #000000;
    border-radius: 10px;
    padding: 25px 30px;
    width: 100%;
    margin-top: 10px;
}

.close-thik:after {
    content: '✖';
    /* UTF-8 symbol */
}

.dialog {
    background: #ddd;
    border: 1px solid #ccc;
    border-radius: 5px;
    float: left;
    height: 200px;
    margin: 20px;
    position: relative;
    width: 150px;

}

button.close-thik {
    position: absolute;
    right: 15px;
    top: 2px;
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
    z-index: 99999999;
}





/* Media Queries */
@media (min-width: 768px) {
    .gallery_main .slider_btns::-webkit-scrollbar {
        height: 6px;
    }

    .menu_drawer>.MuiPaper-root {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: auto;
    }
}

@media (max-width: 420px) {
    /* .delivery_pickup_tabs>.MuiBox-root .tabs_button .MuiTabs-flexContainer button img {
        position: absolute;
        right: 27px;
        display: none;
        margin: 0 auto;
    } */
    .start_next_page .category_options .category_box {
        min-height: auto;
    }

    .customize_page h1 {
        max-width: 50%;
    }

    .page_default {
        height: 100%;
    }

    .MuiContainer-root.main_container {
        box-shadow: none;
    }

    .cake_modal_main .cake_modal_box {
        width: 90%;
    }

    .menu_drawer .menu_list .MuiListItem-root .MuiButtonBase-root {
        padding-bottom: 25px;
    }

    .menu_drawer .menu_order_confirm {
        padding-bottom: 30px;
    }

    .menu_drawer .menu_list {
        padding-top: 30px;
    }

}

.coming_soon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    margin: 0 auto;
}

.message {
    padding: 0px 30px 0px 30px;
}

.message p {
    width: 100%;
    flex-basis: calc(50% - 10px);
    background: #FFFFFF;
    box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 9px;
    padding: 8px 10px;
    font-size: 14px;
    background: #f8b5c4;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: #000000;
}

.MuiFormHelperText-root.Mui-error  {
    text-align: start;
}

/* .banner {
    margin-top: 92px;
} */

@media (min-width: 320px) {
    .MuiModal-root {
        direction: ltr !important;
        margin-top: 100px !important;
    }

}

@media (min-width: 320px) and (max-width: 768px)  {


    .flavored_text {
        padding: 2px !important;
    }
    .uploaded-images-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 8px 10px;
        column-gap: 7px;
        row-gap: 5px;
    }



    .slider_btns_main.search_active .search_close {
        display: flex;
        position: absolute;
        top: 0px;
        left: 90%;
    }

    /* .MuiDialog-paper {
        width: calc(100% - 64px) !important;
    } */

    /* .MuiSvgIcon-root {
        margin-left: -10px !important;
    } */
}

.MuiPopper-root {
    direction: ltr !important;
}

.css-twia2z-MuiPaper-root-MuiDialog-paper {
    max-width: 456px !important;
}

/* .MuiDialog-paper {
    width: calc(58% - 64px) !important;
} */

